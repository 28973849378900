import {
  LANGUAGES,
  DOMAINS,
  HDIDOMAINS,
  ASIAN_COUNTRIES,
} from '@lmig-latam/adil-api-common-lib/constants';
import PropTypes from 'prop-types';

// Shared PropTypes For Environment
// Note: We maintain propsDefition as a separate object because we have an important
// unit test which depends on being able to inspect the number of attributes in this object.
// The prop-types type is locked down so we cannot do this directly on a PropTypes.shape object
const propsDefinition = {
  manageAccountUrl: PropTypes.string.isRequired,
  changePasswordUrl: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  enableCreateInspectionPrefill: PropTypes.bool.isRequired,
  displayImageAnalysis: PropTypes.bool.isRequired,
  cl: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  co: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  ec: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  vn: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  th: PropTypes.shape({
    vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  }).isRequired,
  awsConfig: PropTypes.shape({
    Auth: PropTypes.shape({
      identityPoolId: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      userPoolId: PropTypes.string.isRequired,
      userPoolWebClientId: PropTypes.string.isRequired,
      mandatorySignIn: PropTypes.bool.isRequired,
      oauth: PropTypes.shape({
        domain: PropTypes.string.isRequired,
        scope: PropTypes.arrayOf(PropTypes.string).isRequired,
        redirectSignIn: PropTypes.string.isRequired,
        redirectSignOut: PropTypes.string.isRequired,
        responseType: PropTypes.string.isRequired,
      }).isRequired,
    }),
    Analytics: PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
    }),
    API: PropTypes.shape({
      endpoints: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          endpoint: PropTypes.string.isRequired,
          region: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
};

const PropertyTypes = PropTypes.shape(propsDefinition);

const doesArrayContainCurrentUrl = array =>
  array.some(
    string =>
      window.location.origin.toLowerCase().indexOf(string.toLowerCase()) > -1,
  );

const isNonProduction = () => doesArrayContainCurrentUrl(['-nonprod']);

const isDevelopment = () => doesArrayContainCurrentUrl(['-dev']);

const getCountryCodeFromUrl = () => {
  const host = window.location.host.toLowerCase();

  // Detectar si es un dominio tipo "seguros.com.co"
  if (host.endsWith('hdiseguros.com.co')) {
    // Extraer el subdominio antes de "seguros.com.co"
    const subdomain = host.split('.')[0];
    // Extraer el país del subdominio (ejemplo: "domain-co" -> "co")
    return subdomain.split('-').pop();
  }

  // Para otros dominios, extraer el TLD
  return host.split('.').pop();
};

const isLocalhost = () =>
  doesArrayContainCurrentUrl([
    'localhost',
    'libp45p-18218cf' /* Michael Wallace */,
    'libp45p-364076m' /* Mark McKim */,
    'libp45p-19079d5' /* Jason Priestley */,
  ]);

const isProduction = () =>
  !isNonProduction() && !isDevelopment() && !isLocalhost();

const getCountryCode = () =>
  isLocalhost() ? LANGUAGES.CL : getCountryCodeFromUrl();

const getApiEndpoint = () => {
  const endpointParts = ['https://adil-agent-api'];
  const isHdiDomain = window.location.host
    .toLowerCase()
    .endsWith('hdiseguros.com.co');

  if (isLocalhost() || isDevelopment()) {
    endpointParts.push('-dev');
  } else if (isNonProduction()) {
    endpointParts.push('-nonprod');
  }

  if (isHdiDomain) endpointParts.push(`-${getCountryCode()}`);

  endpointParts.push(
    isHdiDomain ? HDIDOMAINS[getCountryCode()] : DOMAINS[getCountryCode()],
  );

  if (isLocalhost() || isDevelopment()) {
    endpointParts.push('/dev');
  } else if (isNonProduction()) {
    endpointParts.push('/nonprod');
  } else {
    endpointParts.push('/prod');
  }

  endpointParts.push('/api');

  return endpointParts.join('');
};

const isAsianDomain = () =>
  ASIAN_COUNTRIES.includes(getCountryCode().toUpperCase());

export {
  getApiEndpoint,
  getCountryCode,
  isLocalhost,
  isDevelopment,
  isNonProduction,
  isProduction,
  PropertyTypes,
  propsDefinition,
  isAsianDomain,
};
